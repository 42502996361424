import React, { useState } from "react";
import SideNav from "./SideNav";
import { TopNav } from "./TopNav";
import { ImWarning } from "react-icons/im";
import { LittleSideNav } from "./LittleSideNav";
function Layout({ children }) {
  const [openSideNav, setOpenSideNav] = useState(false);
  return (
    <div className="font-poppins  w-full grid grid-rows-flow xl:grid-cols-12 2xl:grid-cols-12  h-full ">
      <div className="col-span-2 hidden xl:flex 2xl:flex  h-full">
       
         
            <TopNav />
         
        
      </div>
      <div className=" mr-2 w-full relative   flex flex-col h-full col-span-12  xl:col-span-10 2xl:col-span-10 gap-2 py-1 px-2">
       
       {/* //warning tem */}
        <div className=" bg-white   z-10  ">
          <div className="w-fit fixed right-0 top-40 px-5  bg-[#FFD93D] flex justify-center rounded py-2">
            <p className="text-center flex items-center gap-2">
              <span>
                <ImWarning className="text-red-600" />
              </span>
              This Page is Under Contruction
            </p>
          </div>
          <div className="relative">
          <div className="sticky top-0 ">
            <SideNav setOpenSideNav={setOpenSideNav} />
          </div>
          </div>
        </div>

        <div className="fixed flex xl:hidden z-50 2xl:hidden  top-0 right-0">
          <LittleSideNav
            setOpenSideNav={setOpenSideNav}
            openSideNav={openSideNav}
          />
        </div>
        <div className="shadow-custom   h-full   rounded-lg">
         {children}
        </div>
      </div>
    </div>



//    <div className="grid grid-cols-12 font-poppins">
//   {/* TopNav for larger screens */}
//   <div className="col-span-2 hidden lg:block">
//     <TopNav />
//   </div>

//   {/* Main content container */}
//   <div className="col-span-12 lg:col-span-10 p-1 flex flex-col">
    
//     {/* Side navigation and little side nav */}
//     <div className="flex w-full justify-between items-start">
//       {/* SideNav for large screens */}
//       <div className="lg:flex sm:flex hidden w-full">
//         <SideNav setOpenSideNav={setOpenSideNav} />
//       </div>

//       {/* LittleSideNav for mobile and small screens */}
//       <div className="lg:hidden flex  ">
//         <LittleSideNav
//           setOpenSideNav={setOpenSideNav}
//           openSideNav={openSideNav}
//         />
//       </div>
//     </div>
    
//     {/* Main content */}
//     <div className="flex-1 overflow-y-auto">
//       {children}
//     </div>
//   </div>
// </div>





  );
}

export default Layout;
