import React from "react";

const ExpertiseCard = ({ label, icon, description }) => {
  return (
    <div
      className="flex gap-10 items-center cursor-pointer justify-between shadow-custom rounded-lg w-full h-full flex-col"
      data-aos="fade-up"        // Apply fade-up animation to the card
      data-aos-duration="1000"  // Duration of the animation in milliseconds
      data-aos-delay="200"      // Delay before animation starts
    >
      <div
        className="navLinks w-full p-6 flex justify-center rounded-t-xl"
        data-aos="zoom-in"         // Zoom-in animation for the icon
        data-aos-duration="800"    // Slightly faster for the icon animation
      >
        <span className="text-white mx-auto text-[80px]">
          <p>{icon}</p>
        </span>
      </div>

      <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="300">
        <p className="text-center text-sm">{description}</p>
      </div>
      
      <div
        className="w-full linear rounded-b-lg py-5"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-delay="400"
      >
        <p className="text-lg text-white text-center">{label}</p>
      </div>
    </div>
  );
};

export default ExpertiseCard;
