import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import image1 from "../../Resources/rms1.png";
import image2 from "../../Resources/rms2.png";
import image3 from "../../Resources/rms3.png";
import image4 from "../../Resources/rms4.png";

const images = [image1, image2, image3, image4];

export const RMSwork = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative px-6 lg:px-32 py-12 space-y-10">
      <div className="text-center mb-6" data-aos="fade-down">
        <h2 className="text-3xl font-semibold text-main">Restaurant Management System</h2>
        <p className="text-gray-600 mt-2">Screenshots showcasing the features and UI</p>
      </div>

      <div className="flex flex-col items-center gap-5">
        <div className="relative w-full max-w-4xl flex items-center justify-center" data-aos="zoom-in">
          <img
            src={images[currentIndex]}
            alt={`Screenshot ${currentIndex + 1}`}
            className="w-full h-auto rounded-lg shadow-lg transition duration-500 ease-in-out transform hover:scale-105"
          />

          <button
            onClick={handlePrevClick}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-main p-3 rounded-full text-white shadow-md hover:bg-secondary transition"
          >
            <FaChevronLeft size={24} />
          </button>

          <button
            onClick={handleNextClick}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-main p-3 rounded-full text-white shadow-md hover:bg-secondary transition"
          >
            <FaChevronRight size={24} />
          </button>
        </div>

        <div className="flex items-center gap-3 mt-4">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-3 h-3 rounded-full transition focus:outline-none ${
                currentIndex === index ? "bg-main" : "bg-gray-400 hover:bg-gray-600"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
