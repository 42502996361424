import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import figmaIcon from '../../Resources/figmaIcon.png';
import reactIcon from '../../Resources/reactIcon.png';
import javaScriptIcon from '../../Resources/javaScriptIcon.png';
import tailwindIcon from '../../Resources/tailwindIcon.png';
import nodeIcon from '../../Resources/nodeIcon.png';
import graphicIcon from '../../Resources/graphicIcon.png';

AOS.init();

const skills = [
  { icon: reactIcon, label: 'React JS', percent: 80 },
  { icon: tailwindIcon, label: 'Tailwind CSS', percent: 90 },
  { icon: nodeIcon, label: 'Node JS', percent: 75 },
  { icon: figmaIcon, label: 'Figma', percent: 70 },
  { icon: graphicIcon, label: 'Photoshop', percent: 65 },
  { icon: javaScriptIcon, label: 'JavaScript', percent: 65 },
];

export const MySkills = () => {
  return (
    <div className="px-8 sm:px-16 md:px-32 py-10 space-y-10  text-black">
      <div className="container mx-auto flex flex-col justify-between items-center">
        <h1 className="text-4xl font-bold text-center   font-oregano text-main ">
          My Skills
        </h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10 md:gap-16">
        {skills.map((skill, i) => (
          <div
            key={i}
            className="flex flex-col space-y-5 items-center gap-2"
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-duration="800"
          >
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-2 text-black cursor-pointer hover:scale-105 transition-transform duration-300 ease-out">
                <img src={skill.icon} alt="" className="h-8 w-8" />
                <span className="text-lg font-semibold">{skill.label}</span>
              </div>
              <span className="text-2xl font-bold text-orange-500">
                {skill.percent}%
              </span>
            </div>

            <div className="w-full bg-light-gray h-2 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-main to-secondary transition-width duration-1000 ease-in-out"
                style={{ width: `${skill.percent}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
