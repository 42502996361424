import HomePage from "./PageComponent/HomePage/HomePage";

import Layout from "./PageComponent/Layout/Layout";
import {  Routes, Route } from 'react-router-dom';
import AboutPage from "./PageComponent/AboutPage/AboutPage";
import Service from "./PageComponent/Service/Service";
import {MySkills} from "./PageComponent/Skill/Skill";
import Education from "./PageComponent/Education/Education";
import { Work } from "./PageComponent/Work/Work";
import Experience from "./PageComponent/Experience/Experience";
import { Contact } from "./PageComponent/Contact/Contact";
import { Blog } from "./PageComponent/Blog/Blog";
import { RMSwork } from "./PageComponent/Work/RMSwork";
function App() {
 
  return (
   <>
 
   <Layout>
    <Routes>
      
      <Route path="/" exact element={<HomePage/>}/>
      <Route path="/About" exact element={<AboutPage/>}/>
      <Route path="/Services" exact element={<Service/>}/>
      <Route path="/Skill" exact element={<MySkills/>}/>
      <Route path="/Education" exact element={<Education/>}/>
      <Route path="/Work" exact element={<Work/>}/>
      <Route path="/Experience" exact element={<Experience/>}/>
      <Route path="/Contact" exact element={<Contact/>}/>
      <Route path="/Blog" exact element={<Blog/>}/>
      <Route path="/Workrms" exact element={<RMSwork/>}/>
    </Routes>
    </Layout>
 
   
  
   </>
  );
}

export default App;

