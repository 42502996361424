import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const initialValues = { name: "", email: "", message: "" };

// Function to send email via EmailJS
const sendEmail = (values, setStatus, setLoading) => {
  setLoading(true); // Set loading state to true when the submit is clicked
  emailjs.send(
    'service_knp9pct',  // Your EmailJS Service ID
    'template_g1sxbci', // Your EmailJS Template ID
    {
      to_name: "Recipient Name",  // Set the recipient's name (this could be dynamic if needed)
      from_name: values.name,     // Sender's name from the form
      message: values.message,    // Message content from the form
    },
    'YY3ajSig_-J6-G5PH'        // Your EmailJS Public API Key
  )
  .then(response => {
    // console.log('SUCCESS:', response);
    setStatus("success");
    setLoading(false); // Stop loading once the email is sent
  })
  .catch(err => {
    // console.log('FAILED:', err.text);
    setStatus("error");
    setLoading(false); // Stop loading on error
  });
};

export const Contact = () => {
  const [status, setStatus] = useState(null);  // Manage form submission status
  const [isLoading, setLoading] = useState(false); // Manage loading state for buffering spinner

  const handleSubmit = (values, { resetForm }) => {
    sendEmail(values, setStatus, setLoading);  // Pass form values to sendEmail and set the status
    resetForm();  // Reset the form after submission
  };

  return (
    <div className="w-full flex flex-col py-10  space-y-10 px-5 lg:px-96">
      <h1 className="text-center text-4xl font-bold font-oregano mb-8">Contact Me</h1>

      {/* Form with animation */}
      <motion.div 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 1 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-full space-y-6"> 
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Your name"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="name" component="p" className="text-red-500 text-xs italic" />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Your email"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="email" component="p" className="text-red-500 text-xs italic" />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 font-bold mb-2" htmlFor="message">
                  Message
                </label>
                <Field
                  id="message"
                  name="message"
                  component="textarea"
                  placeholder="Your message"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="message" component="p" className="text-red-500 text-xs italic" />
              </div>

              <div className="flex items-center justify-center">
                <button 
                  type="submit" 
                  disabled={isSubmitting || isLoading} 
                  className="px-10 py-2 rounded bg-main text-white hover:bg-secondary transition duration-300">
                  {isLoading ? "Sending..." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </motion.div>

      {(isLoading || status) && (
  <motion.div
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ type: 'spring', stiffness: 100, damping: 25 }}
    className={`fixed top-5 left-5 w-80 p-4 rounded shadow-lg z-50 ${status === 'success' ? 'bg-green-100' : status === 'error' ? 'bg-red-100' : 'bg-yellow-100'}`}
  >
    <div className="flex items-center justify-between">
      <h2 className={`text-lg font-bold ${status === 'success' ? 'text-green-800' : status === 'error' ? 'text-red-800' : 'text-yellow-800'}`}>
        {isLoading ? "Sending..." : status === "success" ? "Success!" : "Error!"}
      </h2>
      
      {/* Close Button */}
      <button 
        onClick={() => { setStatus(null); setLoading(false); }}
        className="text-gray-500 hover:text-gray-800 font-bold text-xl"
      >
        &times;
      </button>
    </div>

    {/* Spinner or Message */}
    <p className={`text-sm ${status === 'success' ? 'text-green-600' : status === 'error' ? 'text-red-600' : 'text-yellow-600'}`}>
      {isLoading
        ? "Please wait while your message is being sent."
        : status === 'success'
        ? "Your message has been sent successfully."
        : "There was an error sending your message. Please try again."}
    </p>
  </motion.div>
)}

    </div>
  );
};
