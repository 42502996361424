import React, { useEffect } from "react";
import { GrFacebookOption, GrInstagram } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import profile from "../../Resources/profile.png";
import cv from "../../Resources/Aryan-Pandey-cv.pdf";

function HomePage() {
  const ability = [
    { label: "Design" },
    { label: "Development" },
    { label: "Web Flow" },
    { label: "Communication" },
  ];

  const socialIcon = [
    {
      label: <GrFacebookOption />,
      link: "https://www.facebook.com/aryandev.pandey.7",
    },
    {
      label: <FaLinkedinIn />,
      link: "https://www.linkedin.com/in/aryan-dev-pandey-03ab62255/",
    },
    {
      label: <GrInstagram />,
      link: "https://www.instagram.com/nameisaryan18/",
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="h-full flex flex-col md:flex-row items-center justify-between  p-10">
      {/* Left Section */}
      <div className="w-full md:w-1/2 flex flex-col items-center text-center md:text-left space-y-4" data-aos="fade-right">
        <h2 className="text-4xl font-bold text-main">Hey!</h2>
        <h1 className="text-5xl font-bold text-gray-800">
          I am <span className="text-main">Aryan Dev Pandey</span>
        </h1>
        <div className="flex flex-wrap gap-3 justify-center md:justify-start mt-4">
          {ability.map((val, i) => (
            <div key={i} className="flex items-center gap-2 text-lg text-gray-700">
              <span className="w-2 h-2 rounded-full bg-main"></span>
              <p>{val.label}</p>
            </div>
          ))}
        </div>
        <div className="flex gap-4 mt-6">
          <a href={cv} download="Aryan_Dev_Pandey_CV.pdf" className="main-button transform transition hover:scale-105">
            Download CV
          </a>
          <button className="second-button transform transition hover:scale-105">Let's Talk</button>
        </div>
        <div className="flex gap-4 mt-6">
          {socialIcon.map((val, i) => (
            <a
              key={i}
              href={val.link}
              target="_blank"
              rel="noopener noreferrer"
              className="border border-main p-3 rounded-full text-main hover:text-[#E14D2A] hover:border-[#E14D2A] transition-all duration-300 transform hover:scale-110"
            >
              {val.label}
            </a>
          ))}
        </div>
      </div>

      {/* Right Section with Profile Image */}
      <div className="w-full md:w-1/2 flex justify-center" data-aos="fade-left">
        <img src={profile} alt="profile" className="w-96 h-96 md:w-96 md:h-96 object-cover rounded-full shadow-lg transform transition-all duration-500 hover:scale-105" />
      </div>
      
    </div>
  );
}

export default HomePage;
