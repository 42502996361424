// import React from 'react'
// import ExpertiseCard from './ExpertiseCard'
// import {FaNode} from "react-icons/fa"
// import {AiFillHtml5,AiFillDatabase} from "react-icons/ai"

// function Service() {
  
//   const myExpertise = [
//     {
//       label: "Front-end development",
//       icon: <AiFillHtml5/>,
//       description: "Expertise in building responsive and user-friendly interfaces with React, Redux, and CSS."
//     },
//     {
//       label: "Back-end development",
//       icon: <FaNode />,
//       description: "Expertise in building RESTful APIs with Node.js, Express, and MongoDB."
//     },
//     {
//       label: "Database design",
//       icon: <AiFillDatabase />,
//       description: "Expertise in designing and optimizing MongoDB database schemas for efficient data storage and retrieval."
//     },
 
//   ];
 
//   return (
//     <div className='flex h-full flex-col gap-10 px-4 md:px-32 py-10'>
//       <div className="flex w-full justify-center">
//         <p className="tittle">My Expertise</p>
//       </div>
//       <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-10 md:gap-y-28 gap-x-6 md:gap-x-10">
//         <ExpertiseCard myExpertise={myExpertise} />
//       </div>
      
//     </div>
//   )
// }

// export default Service
import React, { useEffect } from 'react';
import ExpertiseCard from './ExpertiseCard';
import { FaNode } from "react-icons/fa";
import { AiFillHtml5, AiFillDatabase } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";

function Service() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const myExpertise = [
    {
      label: "Front-end Development",
      icon: <AiFillHtml5 size={50} className="text-orange-500" />,
      description: "Building responsive and user-friendly interfaces with React, Redux, and CSS.",
    },
    {
      label: "Back-end Development",
      icon: <FaNode size={50} className="text-green-500" />,
      description: "Creating RESTful APIs with Node.js, Express, and MongoDB.",
    },
    {
      label: "Database Design",
      icon: <AiFillDatabase size={50} className="text-blue-500" />,
      description: "Designing and optimizing MongoDB schemas for efficient data storage and retrieval.",
    },
  ];

  return (
    <div className="flex flex-col items-center gap-12 px-6 md:px-24 py-16 bg-gray-50">
      <div className="text-center" data-aos="fade-up">
        <h2 className="text-4xl font-bold font-oregano text-main mb-2">My Expertise</h2>
       
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-12 gap-x-8 w-full">
        {myExpertise.map((expertise, index) => (
          <ExpertiseCard
            key={index}
            label={expertise.label}
            icon={expertise.icon}
            description={expertise.description}
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Service;

