import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const experienceItems = [
  {
    jobTitle: 'Frontend Developer',
    company: 'Abhyam Robotics Institute of Business And Technology',
    date: '2023 Jan - 2023 April',
    responsibilities: [
      'Developed and maintained frontend code for school, restaurant, and pharmacy management systems',
      'Designed UI/UX for various projects',
      'Collaborated with designers, backend developers, and project managers to implement new features and ensure timely delivery of projects',
      'Optimized website and application performance by implementing best practices and monitoring metrics',
    ],
    type: 'Intern',
  },
];

function Experience() {
  useEffect(() => {
    // Initialize AOS animations when component mounts
    AOS.init({
      duration: 1000, // Duration of animations
      easing: 'ease-in-out', // Smooth easing function
      once: true, // Animation happens only once when scrolling
    });
  }, []);

  return (
    <div className="px-5 xl:px-32 2xl:px-32 py-10">
      <div className="flex flex-col space-y-10">
        <h1 className="tittle text-center text-3xl lg:text-4xl xl:text-5xl font-bold text-main" data-aos="fade-up">
          Experience
        </h1>
        {experienceItems.map((item, i) => (
          <div
            key={item.jobTitle}
            className="bg-white text-main rounded-lg shadow-xl p-6 space-y-4 transform transition-transform hover:scale-105 hover:shadow-2xl ease-in-out duration-300"
            data-aos="fade-up"
            data-aos-delay={i * 200} // Delay each job card animation
          >
            <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4">
              <div>
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-main mb-2">{item.jobTitle}</h2>
                <p className="text-base md:text-lg font-medium text-main">{item.company}</p>
                <p className="text-sm md:text-base flex items-center space-x-2">
                  <span>{item.date}</span>
                  <span className="inline-flex items-center justify-center px-3 py-1 bg-main text-white text-sm md:text-base rounded-full">
                    {item.type}
                  </span>
                </p>
              </div>
            </div>
            <ul className="ml-8 list-disc list-outside space-y-2">
              {item.responsibilities.map((responsibility, index) => (
                <li
                  key={index}
                  className="py-1 flex items-center space-x-2 text-main"
                  data-aos="fade-left"
                  data-aos-delay={index * 200} // Add delay to each responsibility
                >
                  <span className="w-2 h-2 rounded-full bg-main"></span>
                  <span className="text-sm md:text-base">{responsibility}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experience;
